import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'theme-ui'
import Divider from '@components/Divider'

const styles = {
  wide: {
    minHeight: `23rem`,
    bg: `omegaLighter`,
    borderRadius: `lg`
  },
  full: {
    minHeight: `23rem`,
    bg: `omegaLighter`
  },
  inCard: {
    bg: `omegaLighter`,
    borderRadius: t => `${t.radii.lg} ${t.radii.lg} 0 0`,
    mt: -4,
    mx: -4
  }
}

export const PostImage = ({ thumbnail, variant, inCard }) => {
  if (!thumbnail || !thumbnail.hero) return null

  return (
    <>
      <Img
        fluid={thumbnail.hero}
        css={css({ ...styles[variant], ...(inCard && styles.inCard) })}
      />
      <Divider space={3} />
    </>
  )
}

PostImage.defaultProps = {
  variant: 'wide'
}

PostImage.propTypes = {
  variant: PropTypes.oneOf(['wide', 'full']),
  inCard: PropTypes.bool
}
